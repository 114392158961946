import { storableError } from '../util/errors';
import pick from 'lodash/pick';

import { addMarketplaceEntities } from '../ducks/marketplaceData.duck';
import { fetchSkillsAndTools } from '../ducks/SkillsAndTools.duck';

const RESULT_PAGE_SIZE = 8;

// ================ Action types ================ //
export const SET_INITIAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const GET_LISTINGS_REQUEST = 'app/Category/GET_LISTINGS';
export const GET_LISTINGS_SUCCESS = 'app/Category/GET_LISTINGS_SUCCESS';
export const GET_LISTINGS_ERROR = 'app/Category/GET_LISTINGS_ERROR';

export const QUERY_REVIEWS_REQUEST = 'app/ProfilePage/QUERY_REVIEWS_REQUEST';
export const QUERY_REVIEWS_SUCCESS = 'app/ProfilePage/QUERY_REVIEWS_SUCCESS';
export const QUERY_REVIEWS_ERROR = 'app/ProfilePage/QUERY_REVIEWS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/Category/SEARCH_MAP_SET_ACTIVE_LISTING';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  getListingsInProgress: false,
  getListingsError: null,
  currentPageResultIds: [],
  searchParams: null,
  currentPageReviews: [],
  getReviewsInProgress: false,
};

const resultIds = data => data.data.map(l => l.id);

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case GET_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        getListingsInProgress: true,
        getListingsError: null,
      };
    case GET_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        getListingsInProgress: false,
      };
    case GET_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, getListingsInProgress: false, getListingsError: payload };

    case QUERY_REVIEWS_REQUEST:
      return { ...state, queryReviewsError: null, getReviewsInProgress: true };
    case QUERY_REVIEWS_SUCCESS:
      return {
        ...state,
        currentPageReviews: payload,
        getReviewsInProgress: false,
      };
    case QUERY_REVIEWS_ERROR:
      return {
        ...state,
        currentPageReviews: [],
        queryReviewsError: payload,
        getReviewsInProgress: false,
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //
export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const getListingsRequest = searchParams => ({
  type: GET_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const getListingsSuccess = response => ({
  type: GET_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const getListingsError = e => ({
  type: GET_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});

export const queryReviewsRequest = () => ({
  type: QUERY_REVIEWS_REQUEST,
});

export const queryReviewsSuccess = reviews => ({
  type: QUERY_REVIEWS_SUCCESS,
  payload: reviews,
});

export const queryReviewsError = e => ({
  type: QUERY_REVIEWS_ERROR,
  error: true,
  payload: e,
});
// ================ Thunks ================ //

export const getListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(getListingsRequest(searchParams));
  dispatch(queryReviewsRequest());

  const { page, category, ...rest } = searchParams;

  const state = getState();
  const skills = state.SkillsAndTools.skillOptions;
  const tools = state.SkillsAndTools.toolOptions;

  const fetchSkillsAndToolsIfNeeded = () => {
    if (skills.length === 0 || tools.length === 0) {
      return dispatch(fetchSkillsAndTools());
    }
    return Promise.resolve();
  };

  const params = {
    ...rest,
    page,
    perPage: RESULT_PAGE_SIZE,
    sort: ['meta_listingTier', 'createdAt'],
    pub_hidden: false,
    pub_listingType: 'service',
    // TODO check if this works properly. As the result is different from the old system
    // atleast when using filters for language.
    pub_categoryNodes: category,
    include: ['author', 'author.profileImage', 'images'],
    'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
    // 'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.metadata'],
    'fields.image': [
      // Listing page
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.landscape-crop6x',

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Image carousel
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  };

  fetchSkillsAndToolsIfNeeded();

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(getListingsSuccess(response));

      return response;
    })
    .then(async response => {
      const listings = response.data.data;

      const uniqueAuthorIds = new Set(listings.map(l => l.relationships.author.data.id.uuid));
      const uniqueAuthorIdsArray = Array.from(uniqueAuthorIds);

      const userReviewsPromises = uniqueAuthorIdsArray.map(id => {
        return sdk.reviews
          .query({
            subject_id: id,
            state: 'public',
            include: ['subject'],
          })
          .then(userReview => userReview.data.data);
      });

      const userReviews = await Promise.all(userReviewsPromises);

      dispatch(queryReviewsSuccess(userReviews.flat()));

      return response;
    })
    .catch(e => {
      dispatch(getListingsError(storableError(e)));
      throw e;
    });
};
