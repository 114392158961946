import React from 'react';
import { FormattedMessage } from 'react-intl';
import config from '../../config';
import { ProfileSettingsContainer as Container } from '..';
import css from './Container.css';

const ToolsContainer = ({ profileUser, button, toolOptions }) => {
  const tools = profileUser?.attributes?.profile?.publicData?.tools;

  const translation = config.locale === 'fi' ? 'fiTranslation' : 'enTranslation';

  const container = (
    <Container heading={<FormattedMessage id="ProfilePage.tools" />} button={button}>
      {tools &&
        tools.map(e => (
          <div key={e.category} className={css.skillContainer}>
            <div className={css.heading}>
              {toolOptions.find(s => s.key === e.category)?.[translation]}
            </div>
          </div>
        ))}
    </Container>
  );

  // Check whether there is a single object with null values.
  if (!button) {
    if (
      !tools?.length ||
      (Object.values(tools[0])[0] === null && Object.values(tools[0])[1] === null)
    )
      return null;
  }

  return container;
};

export default ToolsContainer;
