import React from 'react';
import classNames from 'classnames';

import { Tooltip } from '../../components';

import css from './VerifiedUserMark.module.css';

import verifiedIcon from '../../assets/verifiedIcon.svg';

const VerifiedUserMark = props => {
  const { className, rootClassName, user, heroSection } = props;
  const classes = classNames(rootClassName || css.root, className);

  const verified = user?.attributes?.profile?.metadata?.verified;

  if (heroSection) {
    return (
      <Tooltip messageId="Tooltip.verifiedMark">
        <span>
          <img className={classes} src={verifiedIcon} alt="Verified" />
        </span>
      </Tooltip>
    );
  } else if (verified) {
    return (
      <Tooltip messageId="Tooltip.verifiedMark">
        <span>
          <img className={classes} src={verifiedIcon} alt="Verified" />
        </span>
      </Tooltip>
    );
  } else if (!verified) {
    return null;
  }
};

export default VerifiedUserMark;
