import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import {
  Avatar,
  OffPlatformReviewAvatar,
  ReviewRating,
  UserDisplayName,
  OffPlatformUserDisplayName,
} from '../../components';

import css from './ReviewsAndTestimonialsContainer.css';

const Review = ({ review, intl }) => {
  const date = review.createdAt;
  const dateString = intl.formatDate(date, { month: 'long', year: 'numeric' });

  return (
    <div className={css.review}>
      {review.onPlatform ? (
        <Avatar className={css.avatar} user={review.author} disableProfileLink={true} />
      ) : (
        <OffPlatformReviewAvatar className={css.avatar} abbreviatedName={review.abbreviatedName} />
      )}

      <div>
        <ReviewRating
          rating={review.rating}
          className={css.mobileReviewRating}
          reviewStarClassName={css.reviewRatingStar}
        />
        <p className={css.reviewContent}>{review.content}</p>
        <p className={css.reviewInfo}>
          {review.onPlatform ? (
            <UserDisplayName user={review.author} intl={intl} />
          ) : (
            <OffPlatformUserDisplayName authorName={review.authorName} intl={intl} />
          )}
          <span className={css.separator} />
          {dateString}
          <span className={css.desktopSeparator} />
          <span className={css.desktopReviewRatingWrapper}>
            <ReviewRating
              rating={review.rating}
              className={css.desktopReviewRating}
              reviewStarClassName={css.reviewRatingStar}
            />
          </span>
        </p>
      </div>
    </div>
  );
};

const ReviewsAndTestimonialsContainerComponent = ({ reviews, testimonials, intl }) => {
  const [showFullReviews, setShowFullReviews] = useState(false);
  const [showFullTestimonials, setShowFullTestimonials] = useState(false);
  const [filteredTestimonials, setFilteredTestimonials] = useState([]);

  useEffect(() => {
    const filteredTestimonials = testimonials.filter(testimonial => {
      return testimonial.state === 'published';
    });
    setFilteredTestimonials(filteredTestimonials);
  }, [testimonials]);

  const reviewsHeading = (
    <FormattedMessage
      id="ReviewsAndTestimonialsContainer.reviewsHeading"
      values={{ count: reviews.length }}
    />
  );
  const testimonialsHeading = (
    <FormattedMessage
      id="ReviewsAndTestimonialsContainer.testimonialsHeading"
      values={{ count: filteredTestimonials.length }}
    />
  );
  const testimonialsSubheading = intl.formatMessage({
    id: 'ReviewsAndTestimonialsContainer.testimonialsSubheading',
  });

  // Show at most 2 initially, show all when expanded
  const visibleReviews = showFullReviews ? reviews : reviews.slice(0, 2);
  const visibleTestimonials = showFullTestimonials
    ? filteredTestimonials
    : filteredTestimonials.slice(0, 2);

  if (reviews.length === 0 && filteredTestimonials.length === 0) {
    return null;
  }

  return (
    <div className={css.reviewsAndTestimonialsContainer}>
      {reviews.length > 0 && (
        <div className={css.reviewsContainer}>
          <div className={css.reviewsHeading}>{reviewsHeading}</div>
          <div className={css.reviews}>
            {visibleReviews.map(review => (
              <Review key={review.id} review={review} intl={intl} />
            ))}
          </div>
          {reviews.length > 2 && (
            <div
              className={css.toggleShowMore}
              onClick={() => setShowFullReviews(!showFullReviews)}
            >
              {showFullReviews
                ? intl.formatMessage({ id: 'FreelancerAboutMeContainer.showLess' })
                : intl.formatMessage({ id: 'FreelancerAboutMeContainer.showMore' })}
            </div>
          )}
        </div>
      )}

      {filteredTestimonials.length > 0 && (
        <div className={css.testimonialsContainer}>
          <div className={css.testimonialHeadingContainer}>
            <div className={css.testimonialsHeading}>{testimonialsHeading}</div>
            <div className={css.testimonialsSubheading}>{testimonialsSubheading}</div>
          </div>
          <div className={css.testimonials}>
            {visibleTestimonials.map(testimonial => (
              <div key={testimonial.id} className={css.testimonial}>
                <div className={css.testimonialAuthorInfo}>
                  <div className={css.testimonialTask}>{testimonial.workExperienceTask}</div>
                  <div className={css.testimonialCustomerName}>
                    {testimonial.workExperienceCustomerName}
                  </div>
                </div>
                <div className={css.testimonialContent}>"{testimonial.content}"</div>
                <div className={css.testimonialAuthorInfo}>
                  <div className={css.testimonialAuthorName}>{testimonial.authorName}</div>
                  <div className={css.testimonialAuthorPosition}>{testimonial.authorPosition}</div>
                </div>
              </div>
            ))}
          </div>
          {testimonials.length > 2 && (
            <div
              className={css.toggleShowMore}
              onClick={() => setShowFullTestimonials(!showFullTestimonials)}
            >
              {showFullTestimonials
                ? intl.formatMessage({ id: 'FreelancerAboutMeContainer.showLess' })
                : intl.formatMessage({ id: 'FreelancerAboutMeContainer.showMore' })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const ReviewsAndTestimonialsContainer = compose(
  connect(null, mapDispatchToProps),
  injectIntl
)(ReviewsAndTestimonialsContainerComponent);

export default ReviewsAndTestimonialsContainer;
