import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { injectIntl } from '../../util/reactIntl';

import { FaMapMarkerAlt, FaPenAlt, FaStar } from 'react-icons/fa';

import { Tooltip, AvatarLarge } from '../../components';

import css from './ProfileUserCard.css';

const BuyerProfileUserCardComponent = ({ profileUser, combinedReviews }) => {
  const displayName = profileUser?.attributes.profile.displayName;

  const reviews =
    combinedReviews?.length > 0 ? (
      <Tooltip messageId="Tooltip.reviews">
        <div className={css.reviews}>
          <FaStar className={css.starIcon} />
          {combinedReviews &&
            combinedReviews?.length !== 0 &&
            (combinedReviews.reduce((a, b) => a + b?.rating, 0) / combinedReviews?.length).toFixed(
              1
            )}
          &nbsp;({combinedReviews && combinedReviews.length})
        </div>
      </Tooltip>
    ) : null;

  const profileImageSection = (
    <div className={css.profileImageContainer}>
      <AvatarLarge user={profileUser} className={css.profileImage} />
    </div>
  );

  const whoAmISection = (
    <div className={css.whoAmIContainer}>
      <div className={css.displayName}>{displayName}</div>
      <div className={css.reviewAndTestimonials}>{reviews}</div>
    </div>
  );

  return (
    <div className={css.profileUserCard}>
      {profileImageSection}
      {whoAmISection}
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const BuyerProfileUserCard = compose(
  connect(mapDispatchToProps),
  injectIntl
)(BuyerProfileUserCardComponent);

export default BuyerProfileUserCard;
