import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  ListingCard,
  NamedLink,
  Page,
  ProjectListingCard,
  EducationContainer,
  WorkExperienceContainer,
  FreelancerProfileUserCard,
  BuyerProfileUserCard,
  FreelancerAboutMeContainer,
  CustomerAboutMeContainer,
  ReviewsAndTestimonialsContainer,
} from '../../components';
import { NotFoundPage, TopbarContainer } from '../../containers';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { withViewport } from '../../util/contextHelpers';
import { ensureCurrentUser, ensureUser } from '../../util/data';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import { REVIEW_TYPE_OF_CUSTOMER, REVIEW_TYPE_OF_PROVIDER, propTypes } from '../../util/types';
import { loadData } from './ProfilePage.duck';

import { FaPenAlt, FaStar } from 'react-icons/fa';

import css from './ProfilePage.css';

const { UUID } = sdkTypes;

const ProfilePageComponent = props => {
  const {
    scrollingDisabled,
    user,
    currentUser,
    userShowError,
    queryListingsError,
    listings,
    offPlatformTestimonials,
    skillOptions,
    toolOptions,
    intl,
  } = props;

  const profileUser = ensureUser(user);
  const profileUserIdExists = profileUser && profileUser.id && profileUser.id.uuid;
  const hasBio = profileUser?.attributes?.profile?.publicData?.bio;

  const combineReviews = () => {
    const { reviews, offPlatformReviews } = props;

    const formattedReviews = reviews.map(review => ({
      content: review?.attributes?.content,
      rating: review?.attributes?.rating,
      createdAt: review?.attributes?.createdAt,
      author: review?.author,
      authorName: review?.author?.attributes?.profile?.displayName,
      abbreviatedName: review?.author?.attributes?.profile?.abbreviatedName,
      type: review?.attributes?.type,
      id: review?.id?.uuid,
      onPlatform: true,
    }));

    const formattedOffPlatformReviews = offPlatformReviews.map(offReview => ({
      content: offReview?.content,
      rating: offReview?.reviewRating,
      createdAt: new Date(offReview?.createdAt),
      authorName: offReview?.displayName,
      abbreviatedName: offReview?.abbreviatedName,
      type: REVIEW_TYPE_OF_PROVIDER,
      id: offReview?._id,
      onPlatform: false,
    }));

    const allReviews = [...formattedReviews, ...formattedOffPlatformReviews];
    allReviews.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    return allReviews;
  };

  const combinedReviews = combineReviews();

  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const isCurrentUser =
    ensuredCurrentUser.id && profileUser.id && ensuredCurrentUser.id.uuid === profileUser.id.uuid;
  const displayName = profileUser?.attributes?.profile?.displayName;

  const accountRole = profileUser?.attributes?.profile?.publicData?.account_role;
  const noIndexing = profileUser?.attributes?.profile?.publicData?.noIndexing;

  const showContainers = accountRole !== 'customer';

  const whoAmI = profileUser?.attributes?.profile?.publicData?.whoAmI;
  const reviewsHeading = (
    <h2>
      <FaStar className={css.starIcon} />
      {combinedReviews &&
        combinedReviews?.length !== 0 &&
        (combinedReviews.reduce((a, b) => a + b?.rating, 0) / combinedReviews?.length).toFixed(1)}
      &nbsp;({combinedReviews && combinedReviews.length})
    </h2>
  );

  const reviewsError = (
    <p className={css.error}>
      <FormattedMessage id="ProfilePage.loadingReviewsFailed" />
    </p>
  );

  const reviewsOfProvider = combinedReviews?.filter(r => r?.type === REVIEW_TYPE_OF_PROVIDER);

  const reviewsOfCustomer = combinedReviews?.filter(r => r?.type === REVIEW_TYPE_OF_CUSTOMER);

  const listingsContainerClasses = classNames(css.listingsContainer, {
    [css.withBioMissingAbove]: !hasBio,
  });

  const renderListing = l =>
    l.attributes?.publicData.listingType === 'service' && !l.attributes?.publicData.hidden ? (
      <li className={css.listing} key={l.id.uuid}>
        <ListingCard listing={l} />
      </li>
    ) : l.attributes?.publicData.listingType === 'project' ? (
      <li className={css.listing} key={l.id.uuid}>
        <ProjectListingCard listing={l} />
      </li>
    ) : null;

  const hasServiceListings = listings.find(
    l =>
      l?.attributes?.publicData?.listingType === 'service' &&
      !l?.attributes?.publicData?.hidden === true
  );

  const asideContent =
    profileUserIdExists && showContainers ? (
      <div className={css.asideContent}>
        <FreelancerProfileUserCard
          profileUser={profileUser}
          combinedReviews={reviewsOfProvider}
          offPlatformTestimonials={offPlatformTestimonials}
        />
      </div>
    ) : profileUserIdExists && !showContainers ? (
      <div className={css.asideContent}>
        <BuyerProfileUserCard profileUser={profileUser} combinedReviews={reviewsOfCustomer} />
      </div>
    ) : null;

  const workExperience = profileUser?.attributes?.profile?.publicData?.workExperience;

  const mainContent =
    profileUserIdExists && showContainers ? (
      <div className={css.mainContent}>
        <FreelancerAboutMeContainer
          profileUser={profileUser}
          skillOptions={skillOptions}
          toolOptions={toolOptions}
        />
        <ReviewsAndTestimonialsContainer
          reviews={reviewsOfProvider}
          testimonials={offPlatformTestimonials}
        />
        {workExperience && workExperience?.length > 0 && workExperience[0]?.startMonth !== null ? (
          <WorkExperienceContainer
            profileUser={profileUser}
            offPlatformTestimonials={offPlatformTestimonials}
          />
        ) : null}
        <EducationContainer profileUser={profileUser} />
        {hasServiceListings ? (
          <div className={listingsContainerClasses}>
            <h2 className={css.listingsTitle}>
              <FormattedMessage
                id="ProfilePage.listingsTitle"
                values={{ user: profileUser.attributes?.profile?.displayName }}
              />
            </h2>
            <ul className={css.listings}>{listings.map(l => renderListing(l))}</ul>
          </div>
        ) : null}
      </div>
    ) : (
      <div className={css.mainContent}>
        <CustomerAboutMeContainer profileUser={profileUser} />
      </div>
    );

  const schemaTitle = intl.formatMessage(
    {
      id: 'ProfilePage.schemaTitle',
    },
    {
      name: displayName,
      whoAmI: whoAmI,
    }
  );

  const averageReviewRating = () => {
    const reviewRating = combinedReviews.map(review => review.attributes?.rating);
    const average = reviewRating.reduce((a, b) => a + b, 0) / reviewRating.length;
    return average;
  };

  const schemaReviews = combinedReviews?.map(review => {
    const publishedDateTemp = new Date(review.createdAt);
    const reviewRating = review.rating;
    const reviewBody = review.content;
    const reviewAuthor = review.displayName;
    const year = publishedDateTemp.getFullYear();
    const month = publishedDateTemp.getMonth();
    const day = publishedDateTemp.getDate();
    const datePublished = `${year}-${month + 1}-${day}`;

    return {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: reviewAuthor,
      },
      datePublished: datePublished,
      reviewBody: reviewBody,
      reviewRating: {
        '@type': 'Rating',
        ratingValue: reviewRating,
        bestRating: '5',
        worstRating: '1',
      },
    };
  });

  const schema = {
    '@context': 'http://schema.org',
    '@type': 'ProfilePage',
    name: schemaTitle,
    review: schemaReviews,
    mainEntity: {
      '@type': 'Person',
      name: displayName,
      jobTitle: whoAmI,
    },
  };

  if (combinedReviews.length > 0) {
    schema.aggregateRating = {
      '@type': 'AggregateRating',
      ratingValue: averageReviewRating(),
      reviewCount: combinedReviews.length,
    };
  }

  if (userShowError && userShowError.status === 404) {
    return <NotFoundPage history={''} location={''} />;
  } else if (userShowError || queryListingsError) {
    return (
      <p className={css.error}>
        <FormattedMessage id="ProfilePage.loadingDataFailed" />
      </p>
    );
  }

  return (
    <Page
      scrollingDisabled={scrollingDisabled}
      title={schemaTitle}
      schema={schema}
      noIndexing={noIndexing}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="ProfilePage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.main}>
          {isCurrentUser ? (
            <div className={css.modifyServiceHeading}>
              <h3 className={css.modifyServiceTitle}>
                <FormattedMessage id="ProfilePage.modifyServiceTitle" />
              </h3>
              <div className={css.editProfileLinkDesktop}>
                <FaPenAlt />
                <NamedLink name="ProfileSettingsPage">
                  <h3>
                    <FormattedMessage id="ProfilePage.editProfileLinkDesktop" />
                  </h3>
                </NamedLink>
              </div>
            </div>
          ) : null}
          <div className={css.mainContainer}>
            <div className={css.aside}>{profileUser && asideContent}</div>
            {profileUser && mainContent}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

ProfilePageComponent.defaultProps = {
  currentUser: null,
  user: null,
  userShowError: null,
  queryListingsError: null,
  reviews: [],
  queryReviewsError: null,
};

const { bool, arrayOf, number, shape } = PropTypes;

ProfilePageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUser: propTypes.currentUser,
  user: propTypes.user,
  userShowError: propTypes.error,
  queryListingsError: propTypes.error,
  listings: arrayOf(propTypes.listing).isRequired,
  queryReviewsError: propTypes.error,
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    userId,
    userShowError,
    queryListingsError,
    userListingRefs,
    reviews,
    queryReviewsError,
    offPlatformTestimonials,
    queryOffPlatformTestimonialsError,
    offPlatformReviews,
    queryOffPlatformReviewsError,
  } = state.ProfilePage;
  const { skillOptions, toolOptions } = state.SkillsAndTools;

  const userMatches = getMarketplaceEntities(state, [{ type: 'user', id: userId }]);
  const user = userMatches.length === 1 ? userMatches[0] : null;

  const listings = getMarketplaceEntities(state, userListingRefs);
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    user,
    userShowError,
    queryListingsError,
    listings,
    reviews,
    queryReviewsError,
    offPlatformTestimonials,
    queryOffPlatformTestimonialsError,
    offPlatformReviews,
    queryOffPlatformReviewsError,
    skillOptions,
    toolOptions,
  };
};

const ProfilePage = compose(
  connect(mapStateToProps),
  withViewport,
  injectIntl
)(ProfilePageComponent);

ProfilePage.loadData = params => {
  const id = new UUID(params.id);
  return loadData(id);
};

export default ProfilePage;
