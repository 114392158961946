import React, { useState } from 'react';
import { array, bool, func, oneOf, object, shape, string } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { parse, stringify } from '../../util/urlHelpers';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { pickSearchParamsOnly } from '../ProjectSearchPage/ProjectSearchPage.helpers';
import { validURLParamsForExtendedData } from '../SearchPage/SearchPage.helpers';
import config from '../../config';
import facebookImage from '../../assets/freedomlySocialShare.png';
import twitterImage from '../../assets/freedomlySocialShare.png';

import {
  Page,
  LayoutWrapperFooter,
  Footer,
  SectionCategoryDescription,
  CategoryBanner,
} from '../../components';
import MainPanel from '../SearchPage/MainPanel';

import { TopbarContainer } from '../../containers';

import { getListings, setActiveListing } from '../../ducks/category.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { setSignUpFrom } from '../SignUpProjectPage/SignUpProjectPage.duck.js';

import css from './ChildCategoryPage.css';
import classNames from 'classnames';
import { propTypes } from '../../util/types';

const siteTitle = config.siteTitle;
const MODAL_BREAKPOINT = 962;

const ChildCategoryPageComponent = props => {
  const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);

  const {
    content,
    filterConfig,
    scrollingDisabled,
    onManageDisableScrolling,
    sortConfig,
    searchParams,
    onActivateListing,
    getListingsInProgress,
    location,
    intl,
    onSetSignUpFrom,
    currentUser,
    combinedReviews,
    getReviewsInProgress,
    skillOptions,
    toolOptions,
  } = props;

  const { mapSearch, page, ...searchInURL } = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  const schemaTitle = intl.formatMessage({ id: content.bannerTitleId }, { siteTitle });
  const schemaDescription = content.showDescription
    ? intl.formatMessage({
        id: `${content.bannerTitleId}PageSchemaDescription`,
      })
    : 'Page.schemaDescription';
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  // urlQueryParams doesn't contain page specific url params
  // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
  const urlQueryParams = pickSearchParamsOnly(searchInURL, filterConfig, sortConfig);

  // Page transition might initially use values from previous search
  const urlQueryString = stringify(urlQueryParams);
  const paramsQueryString = stringify(pickSearchParamsOnly(searchParams, filterConfig, sortConfig));
  const searchParamsAreInSync = urlQueryString === paramsQueryString;

  const validQueryParams = validURLParamsForExtendedData(searchInURL, filterConfig);

  // Set topbar class based on if a modal is open in
  // a child component
  const topbarClasses = isMobileModalOpen
    ? classNames(css.topbarBehindModal, css.topbar)
    : css.topbar;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle + ' | Freedomly.io'}
      facebookImages={[
        { url: `${config.canonicalRootURL}${facebookImage}`, width: 1200, height: 630 },
      ]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <TopbarContainer
        ContainerclassName={topbarClasses}
        currentSearchParams={urlQueryParams}
        currentPage="ChildCategoryPage"
      />
      <div className={css.container}>
        <div className={css.bannerContainer}>
          <CategoryBanner content={content} />
        </div>
        <MainPanel
          {...props}
          searchInProgress={getListingsInProgress}
          onActivateListing={onActivateListing}
          filterConfig={[filterConfig.find(x => x.id === 'filters')]}
          urlQueryParams={validQueryParams}
          onManageDisableScrolling={onManageDisableScrolling}
          searchParamsAreInSync={searchParamsAreInSync}
          searchParamsForPagination={parse(location.search)}
          showAsModalMaxWidth={MODAL_BREAKPOINT}
          onOpenModal={() => setIsMobileModalOpen(true)}
          onCloseModal={() => setIsMobileModalOpen(false)}
          onSetSignUpFrom={onSetSignUpFrom}
          onSetSignUpFromPath={location.pathname}
          currentUser={currentUser}
          currentPageReviews={combinedReviews}
          getReviewsInProgress={getReviewsInProgress}
          location={location}
          skillOptions={skillOptions}
          toolOptions={toolOptions}
        />
        <div className={css.descriptionContainer}>
          {content.showDescription && <SectionCategoryDescription content={content.seoContent} />}
        </div>
      </div>
      <LayoutWrapperFooter>
        <Footer />
      </LayoutWrapperFooter>
    </Page>
  );
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
  onSetSignUpFrom: signUpFrom => dispatch(setSignUpFrom(signUpFrom)),
});

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    currentPageResultIds,
    pagination,
    getListingsInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
    currentPageReviews,
    getReviewsInProgress,
  } = state.category;
  const pageListings = getListingsById(state, currentPageResultIds);

  const { skillOptions, toolOptions } = state.SkillsAndTools;

  return {
    currentUser,
    listings: pageListings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    getListingsInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
    combinedReviews: currentPageReviews,
    getReviewsInProgress,
    skillOptions,
    toolOptions,
  };
};

ChildCategoryPageComponent.defaultProps = {
  listings: [],
  mapListings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  filterConfig: config.custom.filters,
  sortConfig: config.custom.sortConfig,
  activeListingId: null,
  routeName: null,
};

ChildCategoryPageComponent.propTypes = {
  listings: array,
  mapListings: array,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  // onSearchMapListings: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,
  filterConfig: propTypes.filterConfig,
  sortConfig: propTypes.sortConfig,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ChildCategoryPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ChildCategoryPageComponent);

ChildCategoryPage.loadData = options => {
  const { search, category } = options;

  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};

  return getListings({
    ...rest,
    category,
    ...originMaybe,
    page,
  });
};

export default ChildCategoryPage;
