import { denormalisedResponseEntities, ensureOwnListing } from '../util/data';
import { storableError } from '../util/errors';
import { fetchSkills, fetchTools } from '../util/api';
import config from '../config';
import { get } from 'lodash';

// ================ Action types ================ //
export const FETCH_SKILLS_REQUEST = 'app/SkillsAndTools/FETCH_SKILLS_REQUEST';
export const FETCH_SKILLS_SUCCESS = 'app/SkillsAndTools/FETCH_SKILLS_SUCCESS';
export const FETCH_SKILLS_ERROR = 'app/SkillsAndTools/FETCH_SKILLS_ERROR';

export const FETCH_TOOLS_REQUEST = 'app/SkillsAndTools/FETCH_TOOLS_REQUEST';
export const FETCH_TOOLS_SUCCESS = 'app/SkillsAndTools/FETCH_TOOLS_SUCCESS';
export const FETCH_TOOLS_ERROR = 'app/SkillsAndTools/FETCH_TOOLS_ERROR';

// ================ Reducer ================ //
const initialState = {
  skillOptions: [],
  skillsFetchInProgress: false,
  skillsFetchError: null,
  toolOptions: [],
  toolsFetchInProgress: false,
  toolsFetchError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_SKILLS_REQUEST:
      return { ...state, skillsFetchInProgress: true, skillsFetchError: null };
    case FETCH_SKILLS_SUCCESS:
      return { ...state, skillOptions: payload, skillsFetchInProgress: false };
    case FETCH_SKILLS_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, skillsFetchInProgress: false, skillsFetchError: payload };

    case FETCH_TOOLS_REQUEST:
      return { ...state, toolsFetchInProgress: true, toolsFetchError: null };
    case FETCH_TOOLS_SUCCESS:
      return { ...state, toolOptions: payload, toolsFetchInProgress: false };
    case FETCH_TOOLS_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, toolsFetchInProgress: false, toolsFetchError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const fetchSkillsRequest = () => ({ type: FETCH_SKILLS_REQUEST });
export const fetchSkillsSuccess = skills => ({
  type: FETCH_SKILLS_SUCCESS,
  payload: skills,
});
export const fetchSkillsError = e => ({ type: FETCH_SKILLS_ERROR, payload: e });

export const fetchToolsRequest = () => ({ type: FETCH_TOOLS_REQUEST });
export const fetchToolsSuccess = tools => ({
  type: FETCH_TOOLS_SUCCESS,
  payload: tools,
});
export const fetchToolsError = e => ({ type: FETCH_TOOLS_ERROR, payload: e });

// ================ Thunks ================ //
export const fetchSkillsAndTools = () => (dispatch, getState, sdk) => {
  dispatch(fetchSkillsRequest());
  dispatch(fetchToolsRequest());

  const locale = config.locale;
  const translation = locale === 'fi' ? 'fiTranslation' : 'enTranslation';

  if (typeof window === 'object') {
    return Promise.all([fetchSkills(), fetchTools()])
      .then(([skills, tools]) => {
        skills.items.map(skill => {
          skill.label = skill[translation];
          return skill;
        });
        tools.items.map(tool => {
          tool.label = tool[translation];
          return tool;
        });
        dispatch(fetchSkillsSuccess(skills.items));
        dispatch(fetchToolsSuccess(tools.items));
      })
      .catch(e => {
        dispatch(fetchSkillsError(storableError(e)));
        dispatch(fetchToolsError(storableError(e)));
      });
  }
};
