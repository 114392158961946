import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';

import config from '../../config';
import { FaMapMarkerAlt, FaPenAlt, FaStar } from 'react-icons/fa';
import { AiFillEuroCircle } from 'react-icons/ai';

import { SlBubble } from 'react-icons/sl';

import { BsFillCheckCircleFill } from 'react-icons/bs';

import { Modal, CodeOfConductModalContent, Tooltip, AvatarLarge } from '../../components';

import css from './ProfileUserCard.css';

import quoteIcon from '../../assets/quoteIcon.svg';

const FreelancerProfileUserCardComponent = ({
  profileUser,
  combinedReviews,
  offPlatformTestimonials,
  button,
  intl,
}) => {
  const [tosModalOpen, setTosModalOpen] = useState(false);

  const languageOptions = config.custom.filters[3].config.options;

  const displayName = profileUser?.attributes?.profile?.displayName || '';
  const whoAmI = profileUser?.attributes?.profile?.publicData?.whoAmI || '';
  const location = profileUser?.attributes?.profile?.publicData?.location || '';
  const languages = profileUser?.attributes?.profile?.publicData?.languages || [];
  const hourlyFee = profileUser?.attributes?.profile?.publicData?.hourlyFee || '';
  const dontShowHourlyFee =
    profileUser?.attributes?.profile?.publicData?.dontShowHourlyFee || false;

  const cocAccepted = profileUser?.attributes?.profile?.publicData?.cocAccepted || '';

  const cocHeading = intl.formatMessage({ id: 'ProfileSettingsForm.cocHeading' });
  const cocText = intl.formatMessage({ id: 'ProfileSettingsForm.cocText' });
  const openCoc = intl.formatMessage({ id: 'ProfileSettingsForm.cocOpenCoc' });

  const languagesString =
    languages &&
    languages
      .filter(l => l.level === 'fluent' || l.level === 'native')
      .map((e, index, arr) => (
        <span key={e.language}>
          {languageOptions.find(l => l.key === e.language)?.label}
          {index < arr.length - 1 ? ',' : ''}
        </span>
      ));

  const cocModal = (
    <Modal
      id="ProfileSettingsForm.coc"
      isOpen={tosModalOpen}
      onClose={() => setTosModalOpen(false)}
      onManageDisableScrolling={manageDisableScrolling}
      usePortal
    >
      <CodeOfConductModalContent />
    </Modal>
  );

  const profileImageSection = (
    <div className={css.profileImageContainer}>
      <AvatarLarge user={profileUser} className={css.profileImage} />
    </div>
  );

  const reviews =
    combinedReviews?.length > 0 ? (
      <Tooltip messageId="Tooltip.reviews">
        <div className={css.reviews}>
          <FaStar className={css.starIcon} />
          {combinedReviews &&
            combinedReviews?.length !== 0 &&
            (combinedReviews.reduce((a, b) => a + b?.rating, 0) / combinedReviews?.length).toFixed(
              1
            )}
          &nbsp;({combinedReviews && combinedReviews.length})
        </div>
      </Tooltip>
    ) : null;

  const testimonials =
    offPlatformTestimonials?.length > 0 ? (
      <Tooltip messageId="Tooltip.testimonials">
        <div className={css.testimonials}>
          <img className={css.quoteIcon} src={quoteIcon} alt="quote" />(
          {offPlatformTestimonials && offPlatformTestimonials?.length})
        </div>
      </Tooltip>
    ) : null;

  const whoAmISection = (
    <div className={css.whoAmIContainer}>
      <div className={css.displayName}>{displayName}</div>
      <div className={css.whoAmI}>{whoAmI}</div>
      <div className={css.reviewAndTestimonials}>
        {reviews}
        {testimonials}
      </div>
    </div>
  );

  const locationSection = location && location !== '' && location?.selectedPlace !== null && (
    <div className={css.locationSection}>
      <FaMapMarkerAlt className={css.mapMarkerIcon} />
      {location?.search.split(',')[0]}
    </div>
  );

  const languagesSection = (
    <div className={css.languageSection}>
      <SlBubble className={css.languageIcon} />
      {languagesString}
    </div>
  );

  const hourlyFeeSection = dontShowHourlyFee ? (
    <div className={css.hourlyFeeSection}>
      <AiFillEuroCircle className={css.euroIcon} />
      {hourlyFee}
    </div>
  ) : null;

  const cocSection = (
    <div className={css.cocSection}>
      <div className={css.cocSectionTitle}>{cocHeading}</div>

      <div className={css.cocContainer}>
        <BsFillCheckCircleFill className={cocAccepted ? css.cocIcon : css.cocIconNotAccepted} />
        <div className={css.cocText}>{cocText}</div>
      </div>
      {!button ? (
        <div className={css.openCoc} onClick={() => setTosModalOpen(true)}>
          {openCoc}
        </div>
      ) : null}
    </div>
  );

  return (
    <div className={css.profileUserCard}>
      {profileImageSection}
      {whoAmISection}
      <div className={css.separator} />
      {locationSection}
      {languagesSection}
      {hourlyFeeSection}
      {cocSection}
      {cocModal}
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const FreelancerProfileUserCard = compose(
  connect(mapDispatchToProps),
  injectIntl
)(FreelancerProfileUserCardComponent);

export default FreelancerProfileUserCard;
