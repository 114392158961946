import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureStripeCustomer, ensurePaymentMethodCard } from '../../util/data';
import {
  InlineTextButton,
  NamedLink,
  VerifiedUserMark,
  Modal,
  PrimaryButton,
} from '../../components';
import { EnquiryForm, PaymentMethodsForm } from '../../forms';

import { func, shape } from 'prop-types';
import { FaHeart, FaRegHeart } from 'react-icons/fa';

import css from './ListingPage.css';

const SectionHeading = props => {
  const {
    richTitle,
    category,
    hostLink,
    showContactUser,
    onContactUser,
    user,
    categoryKey,
    mainCategory,
    mainCategoryLabel,
    filterConfig,
    updateFavoriteListings,
    favoriteListings,
    listing,
    title,
    currentUser,
    authorDisplayName,
    isEnquiryModalOpen,
    showHiringSteps,
    onCloseEnquiryModal,
    isPaymentMethodModalOpen,
    onClosePaymentMethodModalOpen,
    sendEnquiryError,
    sendEnquiryInProgress,
    onSubmitEnquiry,
    onManageDisableScrolling,
    handlePaymentMethodSubmit,
    addPaymentMethodError,
    deletePaymentMethodError,
    createStripeCustomerError,
    handleCardSetupError,
    paymentMethodFormInProgress,
    paymentMethodModalState,
    setPaymentMethodModalState,
    onOpenEnquiryModal,
  } = props;

  const hasDefaultPaymentMethod =
    currentUser &&
    ensureStripeCustomer(currentUser.stripeCustomer).attributes.stripeCustomerId &&
    ensurePaymentMethodCard(currentUser.stripeCustomer.defaultPaymentMethod).id;

  const userName = currentUser
    ? `${currentUser.attributes.profile.firstName} ${currentUser.attributes.profile.lastName}`
    : null;

  const initalValuesForStripePayment = { name: userName };

  const breadCrumbSeparator = '>';

  const categories = filterConfig.find(x => x.id === 'category').config.options;

  const findLinkToPage = (categories, categoryToCompareTo) => {
    const name = categories.find(category => category.key === categoryToCompareTo);
    return name.name;
  };

  const isFavoriteListing =
    favoriteListings && favoriteListings.some(x => x.uuid === listing.id.uuid);

  const handleAddRemoveFavorite = () => {
    // If not logged in redirect to signup.
    if (!currentUser) {
      const openModal = false;
      onContactUser(openModal);
    } else {
      const favoriteListingsUuids = favoriteListings
        .map(listing => listing.uuid)
        .filter(favoriteListing => favoriteListing !== listing.id.uuid);

      if (isFavoriteListing) {
        updateFavoriteListings(favoriteListingsUuids);
      } else {
        updateFavoriteListings([...favoriteListingsUuids, listing.id.uuid]);
      }
    }
  };

  const handleBookCall = () => {
    if (typeof window === 'object') {
      window.Intercom('showSpace', 'home');
      onClosePaymentMethodModalOpen();
    }
  };

  const handlePaymentModalClose = () => {
    onClosePaymentMethodModalOpen();
    // setPaymentMethodModalState(0);
  };

  const handlePaymentMethodAddedEnquiry = () => {
    onClosePaymentMethodModalOpen();
    onOpenEnquiryModal();
  };

  const handleSetPaymentMethodModalState = state => {
    setPaymentMethodModalState(state);
  };

  const paymentMethodModalContent = () => {
    if (paymentMethodModalState === 0) {
      return (
        <div className={css.paymentMethodModalContent}>
          <div className={css.paymentMethodModalTitle}>
            <FormattedMessage id="ListingPage.paymentMethodModalTitle" />
          </div>
          <div className={css.paymentMethodModalSubTitle}>
            <FormattedMessage id="ListingPage.paymentMethodModalSubTitle" />
          </div>
          <div className={css.paymentMethodModalDescription}>
            <FormattedMessage id="ListingPage.paymentMethodModalDescription" />
          </div>
          <PrimaryButton
            className={css.paymentMethodFormPrimaryButton}
            onClick={() => handleSetPaymentMethodModalState(2)}
          >
            <FormattedMessage id="ListingPage.paymentMethodModalAddCard" />
          </PrimaryButton>
          <PrimaryButton
            className={css.paymentMethodFormSecondaryButton}
            onClick={() => handleSetPaymentMethodModalState(1)}
          >
            <FormattedMessage id="ListingPage.paymentMethodModalAskForInvoice" />
          </PrimaryButton>
        </div>
      );
    }
    if (paymentMethodModalState === 1) {
      return (
        <div className={css.paymentMethodModalContent}>
          <div
            className={css.paymentMethodModalBack}
            onClick={() => handleSetPaymentMethodModalState(0)}
          >
            <FormattedMessage id="ListingPage.paymentMethodModalBack" />
          </div>
          <div className={css.paymentMethodModalTitle}>
            <FormattedMessage id="ListingPage.paymentMethodModalTitle2" />
          </div>
          <div className={css.paymentMethodModalDescription}>
            <FormattedMessage id="ListingPage.paymentMethodModalDescription2" />
          </div>
          {/* <div className={css.paymentMethodModalDescription}>
            <FormattedMessage id="ListingPage.paymentMethodModalDescription3" />
          </div> */}
          <PrimaryButton
            className={css.paymentMethodFormPrimaryButton}
            onClick={() => handleSetPaymentMethodModalState(2)}
          >
            <FormattedMessage id="ListingPage.paymentMethodModalAddCard" />
          </PrimaryButton>
          <PrimaryButton
            className={css.paymentMethodFormSecondaryButton}
            onClick={() => handleBookCall()}
          >
            <FormattedMessage id="ListingPage.paymentMethodModalAskForInvoice2" />
          </PrimaryButton>
        </div>
      );
    }
    if (paymentMethodModalState === 2) {
      return (
        <PaymentMethodsForm
          className={css.paymentForm}
          formId="PaymentMethodsForm"
          initialValues={initalValuesForStripePayment}
          onSubmit={handlePaymentMethodSubmit}
          hasDefaultPaymentMethod={hasDefaultPaymentMethod}
          addPaymentMethodError={addPaymentMethodError}
          deletePaymentMethodError={deletePaymentMethodError}
          createStripeCustomerError={createStripeCustomerError}
          handleCardSetupError={handleCardSetupError}
          inProgress={paymentMethodFormInProgress}
          handleSetPaymentMethodModalState={handleSetPaymentMethodModalState}
          isListingPage={true}
        />
      );
    }
    if (paymentMethodModalState === 3) {
      return (
        <div className={css.paymentMethodModalContent}>
          <div className={css.paymentMethodModalTitle}>
            <FormattedMessage id="ListingPage.paymentMethodModalTitle3" />
          </div>
          <div className={css.paymentMethodModalSubTitle}>
            <FormattedMessage id="ListingPage.paymentMethodModalSubTitle3" />
          </div>
          <PrimaryButton
            className={css.paymentMethodFormPrimaryButton}
            onClick={() => handlePaymentMethodAddedEnquiry()}
          >
            <FormattedMessage id="ListingPage.paymentMethodModalCardAdded" />
          </PrimaryButton>
        </div>
      );
    }
  };

  return (
    <>
      <div className={css.sectionHeading}>
        <div className={css.heading}>
          <NamedLink name={findLinkToPage(categories, mainCategory)}>{mainCategoryLabel}</NamedLink>
          <span className={css.separator}>{breadCrumbSeparator}</span>

          <NamedLink name={findLinkToPage(categories, categoryKey)}>{category}</NamedLink>
          <h1 className={css.title}>{richTitle}</h1>
          <div className={css.author}>
            <FormattedMessage id="ListingPage.hostedBy" values={{ name: hostLink }} />
            {/* <VerifiedUserMark user={user} /> */}

            {showContactUser ? (
              <span className={css.contactWrapper}>
                <span className={css.separator}>•</span>
                <InlineTextButton rootClassName={css.contactLink} onClick={onContactUser}>
                  <FormattedMessage id="ListingPage.contactUser" />
                </InlineTextButton>
              </span>
            ) : null}
            {showContactUser && !isFavoriteListing ? (
              <span className={css.favoriteWrapper}>
                <span className={css.separator}>•</span>
                <FaRegHeart />
                <InlineTextButton
                  rootClassName={css.toFavoriteListings}
                  onClick={handleAddRemoveFavorite}
                >
                  <FormattedMessage id="ListingPage.saveToFavoriteListings" />
                </InlineTextButton>
              </span>
            ) : showContactUser && isFavoriteListing ? (
              <span className={css.favoriteWrapper}>
                <span className={css.separator}>•</span>
                <FaHeart />
                <InlineTextButton
                  rootClassName={css.toFavoriteListings}
                  onClick={handleAddRemoveFavorite}
                >
                  <FormattedMessage id="ListingPage.removeFromFavoriteListings" />
                </InlineTextButton>
              </span>
            ) : null}
          </div>
          <Modal
            id="ListingPage.enquiry"
            contentClassName={css.enquiryModalContent}
            isOpen={isEnquiryModalOpen}
            onClose={onCloseEnquiryModal}
            usePortal
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <EnquiryForm
              className={css.enquiryForm}
              submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
              listingTitle={title}
              authorDisplayName={authorDisplayName}
              showHiringSteps={showHiringSteps}
              sendEnquiryError={sendEnquiryError}
              onSubmit={onSubmitEnquiry}
              inProgress={sendEnquiryInProgress}
              listing={listing}
            />
          </Modal>
          <Modal
            id="ListingPage.paymentMethod"
            contentClassName={css.paymentMethodModalContent}
            isOpen={isPaymentMethodModalOpen}
            onClose={handlePaymentModalClose}
            usePortal
            onManageDisableScrolling={onManageDisableScrolling}
          >
            {paymentMethodModalContent()}
          </Modal>
        </div>
      </div>
    </>
  );
};

SectionHeading.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  updateFavoriteListings: func.isRequired,
};

export default compose(withRouter)(SectionHeading);
