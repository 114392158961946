import { fetchSkillsAndTools } from '../../ducks/SkillsAndTools.duck';

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}

export const loadData = () => (dispatch, getState) => {
  const state = getState();
  const skills = state.SkillsAndTools.skillOptions;
  const tools = state.SkillsAndTools.toolOptions;

  const fetchSkillsAndToolsIfNeeded = () => {
    if (skills.length === 0 || tools.length === 0) {
      return dispatch(fetchSkillsAndTools());
    }
    return Promise.resolve();
  };

  return Promise.all([fetchSkillsAndToolsIfNeeded()]);
};
