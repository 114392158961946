import React, { useState } from 'react';
import { compose } from 'redux';
import { injectIntl } from '../../util/reactIntl';
import config from '../../config';

import css from './CustomerAboutMeContainer.css';

const CustomerAboutMeContainerComponent = ({ profileUser, intl }) => {
  const [showFullBio, setShowFullBio] = useState(false);

  const header = intl.formatMessage({
    id: 'FreelancerAboutMeContainer.header',
  });
  const bio = profileUser?.attributes?.profile?.publicData?.bio || '';

  // Show only part of the bio if it's long
  const bioPreviewLength = 600;
  const isBioLong = bio.length > bioPreviewLength;
  const displayedBio = showFullBio || !isBioLong ? bio : `${bio.slice(0, bioPreviewLength)}...`;

  return (
    <div className={css.aboutMeContainer}>
      <div className={css.sectionTitle}>{header}</div>
      <div className={css.bio}>
        <div dangerouslySetInnerHTML={{ __html: displayedBio }} />
        {isBioLong && (
          <div className={css.toggleShowMore} onClick={() => setShowFullBio(!showFullBio)}>
            {showFullBio
              ? intl.formatMessage({ id: 'FreelancerAboutMeContainer.showLess' })
              : intl.formatMessage({ id: 'FreelancerAboutMeContainer.showMore' })}
          </div>
        )}
      </div>
    </div>
  );
};

const CustomerAboutMeContainer = compose(injectIntl)(CustomerAboutMeContainerComponent);

export default compose(injectIntl)(CustomerAboutMeContainer);
