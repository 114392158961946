import React, { useState } from 'react';
import { compose } from 'redux';
import { injectIntl } from '../../util/reactIntl';
import config from '../../config';

import css from './FreelancerAboutMeContainer.css';

const FreelancerAboutMeContainerComponent = ({ profileUser, skillOptions, toolOptions, intl }) => {
  const [showFullBio, setShowFullBio] = useState(false);
  const [showFullSkills, setShowFullSkills] = useState(false);
  const [showFullHobbies, setShowFullHobbies] = useState(false);

  const locale = config.locale;
  const translationKey = locale === 'fi' ? 'fiTranslation' : 'enTranslation';
  const hobbyOptions = config.custom.hobbyOptions;

  const header = intl.formatMessage({ id: 'FreelancerAboutMeContainer.header' });
  const skillsAndToolsHeading = intl.formatMessage({
    id: 'FreelancerAboutMeContainer.skillsAndTools',
  });

  const bio = profileUser?.attributes?.profile?.publicData?.bio || '';
  const skills = profileUser?.attributes?.profile?.publicData?.experience || [];
  const tools = profileUser?.attributes?.profile?.publicData?.tools || [];
  const hobbies = profileUser?.attributes?.profile?.publicData?.hobbies || [];

  // Sort skills by `isMainSkill`
  const sortedSkills = skills
    .sort((a, b) => (b.isMainSkill ? 1 : 0) - (a.isMainSkill ? 1 : 0))
    .map(skill => ({ ...skill, isSkill: true }));

  // Combine skills and tools
  const skillsAndTools = [...sortedSkills, ...tools];

  // Determine the initial set of skills/tools to show (max 5)
  const initialSkillsAndTools = skillsAndTools.slice(0, 5);
  const remainingSkillsAndTools = skillsAndTools.slice(5);

  // Check if "Show More" button should be displayed
  const hasMoreSkills = remainingSkillsAndTools.length > 0;

  // Lookup function for translated category names
  const getTranslatedCategory = category =>
    skillOptions.find(s => s.key === category)?.[translationKey] ||
    toolOptions.find(t => t.key === category)?.[translationKey] ||
    category;

  // Show only part of the bio if it's long
  const bioPreviewLength = 600;
  const isBioLong = bio.length > bioPreviewLength;
  const displayedBio = showFullBio || !isBioLong ? bio : `${bio.slice(0, bioPreviewLength)}...`;

  return (
    <div className={css.aboutMeContainer}>
      <div className={css.sectionTitle}>{header}</div>
      <div className={css.bio}>
        <div dangerouslySetInnerHTML={{ __html: displayedBio }} />
        {isBioLong && (
          <div className={css.toggleShowMore} onClick={() => setShowFullBio(!showFullBio)}>
            {showFullBio
              ? intl.formatMessage({ id: 'FreelancerAboutMeContainer.showLess' })
              : intl.formatMessage({ id: 'FreelancerAboutMeContainer.showMore' })}
          </div>
        )}
      </div>
      <div className={css.sectionSkillsAndTools}>
        <div className={css.skillsAndToolsHeading}>{skillsAndToolsHeading}</div>
        <div className={css.skillsAndTools}>
          {(showFullSkills ? skillsAndTools : initialSkillsAndTools).map(({ category }) => (
            <div key={category} className={css.skillContainer}>
              <div className={css.heading}>{getTranslatedCategory(category)}</div>
            </div>
          ))}
          {hasMoreSkills && (
            <div className={css.toggleShowMore} onClick={() => setShowFullSkills(!showFullSkills)}>
              {showFullSkills
                ? intl.formatMessage({ id: 'FreelancerAboutMeContainer.showLess' })
                : intl.formatMessage({ id: 'FreelancerAboutMeContainer.showMore' })}
            </div>
          )}
        </div>
      </div>
      <div className={css.sectionHobbies}>
        <div className={css.hobbiesHeading}>
          {intl.formatMessage({ id: 'FreelancerAboutMeContainer.hobbies' })}
        </div>
        <div className={css.hobbies}>
          {(showFullHobbies ? hobbies : hobbies.slice(0, 5)).map(({ category }) => (
            <div key={category} className={css.hobbyContainer}>
              <div className={css.heading}>{hobbyOptions.find(h => h.key === category)?.label}</div>
            </div>
          ))}
          {hobbies.length > 5 && (
            <div
              className={css.toggleShowMore}
              onClick={() => setShowFullHobbies(!showFullHobbies)}
            >
              {showFullHobbies
                ? intl.formatMessage({ id: 'FreelancerAboutMeContainer.showLess' })
                : intl.formatMessage({ id: 'FreelancerAboutMeContainer.showMore' })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const FreelancerAboutMeContainer = compose(injectIntl)(FreelancerAboutMeContainerComponent);

export default compose(injectIntl)(FreelancerAboutMeContainer);
