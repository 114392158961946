import React, { Component, useRef, useEffect, useState, useMemo } from 'react';
import { string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { propTypes } from '../../util/types';
import { ensureListing, ensureUser } from '../../util/data';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { NamedLink, ResponsiveImage, AvatarMedium } from '..';
import css from './ListingUserCard.css';
import { FaStar, FaHeart, FaRegHeart } from 'react-icons/fa';
import renderHTML from 'react-render-html';

const VIEWPORT_XSMALL = 360;
const VIEWPORT_SMALL = 720;
const VIEWPORT_MEDIUM = 962;
const VIEWPORT_LARGE = 1301;
const VIEWPORT_XLARGE = 1921;

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingUserCardComponent = props => {
  const {
    className,
    rootClassName,
    listing,
    renderSizes,
    setActiveListing,
    updateFavoriteListings,
    favoriteListings,
    currentUser,
    onContactUser,
    reviews,
    skillOptions,
    toolOptions,
  } = props;

  function removeHtmlTags(inputString) {
    var stringWithoutHtml = inputString
      .replace(/<\/p><p>/g, ' ')
      .replace(/<\/li><\/ol>/g, '. ')
      .replace(/<\/li><\/ul>/g, '. ')
      .replace(/<ul>|<ol>/g, ' ')
      .replace(/<\/ul>|<\/ol>/g, ' ')
      .replace(/<li>/g, '')
      .replace(/<\/li>/g, ', ')
      .replace(/<li>/g, ' ')
      .replace(/<\/li>/g, ',')
      .replace(/<[^>]*>/g, '');

    return stringWithoutHtml;
  }

  const skillAndToolOptions = skillOptions.concat(toolOptions);

  const translation = config.locale === 'fi' ? 'fiTranslation' : 'enTranslation';

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);

  const id = currentListing.id.uuid;
  const { title = '' } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const tempBio = currentListing?.attributes?.publicData?.authorBio;

  const authorBio = tempBio ? removeHtmlTags(tempBio) : null;

  const authorWhoAmI = currentListing?.attributes?.publicData?.authorWhoAmI || 'Freelancer';
  const authorExperience = currentListing?.attributes?.publicData?.authorSkills;
  const authorTools = currentListing?.attributes?.publicData?.authorTools;
  const experienceAndTools = authorTools ? authorExperience.concat(authorTools) : authorExperience;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  const secondImage =
    currentListing.images && currentListing.images.length > 1 ? currentListing.images[1] : null;
  const thirdImage =
    currentListing.images && currentListing.images.length > 2 ? currentListing.images[2] : null;
  const fourthImage =
    currentListing.images && currentListing.images.length > 3 ? currentListing.images[3] : null;

  const fireGtmEvent = () => {
    if (typeof window === 'object') {
      window.dataLayer.push({
        event: 'select_item',
        ecommerce: {
          items: [
            {
              item_name: listing.attributes.title,
              item_id: listing.id.uuid,
              price: listing.attributes.publicData?.priceArray
                ? '' + listing.attributes.publicData?.priceArray[0]?._packagePrice / 100
                : '' + listing.attributes.price.amount / 100,
              item_brand: listing.author.attributes.profile.displayName,
              item_category: listing.attributes.publicData.category,
              item_list_name: 'search_results',
              quantity: '1',
            },
          ],
        },
      });
    }
  };

  const reviewsHeading = (
    <div className={css.reviewsHeading}>
      <FaStar className={css.starIcon} />
      {reviews &&
        reviews.length !== 0 &&
        (reviews.reduce((a, b) => a + b?.attributes?.rating, 0) / reviews?.length).toFixed(1)}
      &nbsp;({reviews && reviews.length})
    </div>
  );

  const bio = authorBio && renderHTML(authorBio);

  const currentUserRole = currentUser?.attributes?.profile?.publicData?.account_role;

  const showContactUser =
    !currentUser ||
    (currentUserRole === 'customer' && currentUser && currentUser.id.uuid !== author.id.uuid);

  const isFavoriteListing =
    favoriteListings && favoriteListings.some(x => x.uuid === listing.id.uuid);

  const handleAddRemoveFavorite = () => {
    // If not logged in redirect to signup.
    if (!currentUser) {
      const openModal = false;
      onContactUser(openModal);
    } else {
      const favoriteListingsUuids = favoriteListings
        .map(listing => listing.uuid)
        .filter(favoriteListing => favoriteListing !== listing.id.uuid);

      if (isFavoriteListing) {
        updateFavoriteListings(favoriteListingsUuids);
      } else {
        updateFavoriteListings([...favoriteListingsUuids, listing.id.uuid]);
      }
    }
  };

  const [viewportWidth, setViewportWidth] = useState(
    typeof window === 'object' ? window.innerWidth : 0
  );
  useEffect(() => {
    if (typeof window === 'object') {
      setViewportWidth(window.innerWidth);
    }
  }, []);

  const [popUpIsShown, setPopUpIsShown] = useState(false);
  const popUpRef = useRef(null);

  if (typeof window === 'object') {
    function updateWidth() {
      setViewportWidth(window.innerWidth);
    }

    // updateWidth();
    window.addEventListener('resize', updateWidth);
  }

  useEffect(() => {
    if (typeof window === 'object') {
      const handleClickOutside = event => {
        if (popUpRef.current && !popUpRef.current.contains(event.target)) {
          setPopUpIsShown(false);
        }
      };

      // Attach the event listener when the popup is shown
      if (popUpIsShown) {
        document.addEventListener('click', handleClickOutside);
      } else {
        // Remove the event listener when the popup is hidden
        document.removeEventListener('click', handleClickOutside);
      }

      // Cleanup the event listener when the component is unmounted
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }
  }, [popUpIsShown]);

  const parentWidth = useMemo(() => {
    let calculatedWidth;
    switch (true) {
      case viewportWidth <= VIEWPORT_XSMALL:
        calculatedWidth = 290;
        break;
      case viewportWidth <= VIEWPORT_SMALL:
        calculatedWidth = viewportWidth - 150;
        break;
      case viewportWidth <= VIEWPORT_MEDIUM:
        calculatedWidth = viewportWidth - 200;
        break;
      case viewportWidth <= VIEWPORT_LARGE:
        calculatedWidth = VIEWPORT_LARGE - 400;
        break;
      case viewportWidth <= VIEWPORT_XLARGE:
        calculatedWidth = (VIEWPORT_LARGE - 220) / 2;
        break;
      default:
        calculatedWidth = 700;
    }
    return calculatedWidth;
  }, [viewportWidth]);

  const ExperienceContainer = props => {
    const { items } = props;
    const containerRef = useRef(null);
    const [visibleItems, setVisibleItems] = useState(items);

    useEffect(() => {
      let totalWidth = 0;
      let itemsToDisplay = [];

      if (containerRef.current && containerRef.current.children) {
        for (let index = 0; index < containerRef.current.children.length; index++) {
          const itemRef = containerRef.current.children[index];

          if (itemRef) {
            const itemWidth = itemRef.getBoundingClientRect().width;
            totalWidth += itemWidth;

            if (totalWidth <= parentWidth - itemsToDisplay.length * 14) {
              itemsToDisplay.push(items[index]);
            } else {
              break; // Exit loop if the total width exceeds parentWidth
            }
          }
        }
      }

      setVisibleItems(itemsToDisplay);
    }, [items]);

    return (
      <div className={css.experienceContainer}>
        <div
          ref={containerRef}
          style={{
            maxWidth: parentWidth,
            display: 'flex',
            flexDirection: 'row',
            overflow: 'hidden',
            maxHeight: 70,
            gap: 14,
          }}
        >
          {visibleItems &&
            visibleItems.length > 0 &&
            visibleItems.map((e, ind) => (
              <div key={ind} className={css.skillContainer}>
                <div className={css.heading}>
                  {skillAndToolOptions.find(s => s.key === e.category)?.[translation]}
                </div>
              </div>
            ))}
        </div>
        <div className={css.experienceLeft} onClick={() => setPopUpIsShown(!popUpIsShown)}>
          {experienceAndTools &&
            experienceAndTools.length > visibleItems.length &&
            `+ ${experienceAndTools.length - visibleItems.length}`}
        </div>
        <div
          ref={popUpRef}
          className={popUpIsShown ? css.experiencePopup : css.experiencePopupHidden}
        >
          {experienceAndTools &&
            experienceAndTools.length > 0 &&
            experienceAndTools
              .filter(e => !visibleItems.includes(e))
              .map((e, ind) => (
                <div key={ind} className={css.skillContainer}>
                  <div className={css.heading}>
                    {skillAndToolOptions.find(s => s.key === e.category)?.[translation]}
                  </div>
                </div>
              ))}
        </div>
      </div>
    );
  };

  return (
    <div
      className={classes}
      onMouseEnter={() => setActiveListing(currentListing.id)}
      onMouseLeave={() => setActiveListing(null)}
    >
      <div onClick={fireGtmEvent}>
        <div className={css.authorContainer}>
          <NamedLink name="ListingPage" params={{ id, slug }} className={css.authorContent}>
            <div className={css.avatarContainer}>
              <AvatarMedium className={css.avatar} user={author} disableProfileLink />
            </div>
            <div className={css.authorInfo}>
              <div className={css.authorName}>
                <FormattedMessage id="ListingCard.hostedBy" values={{ authorName }} />
                {reviews.length > 0 && reviewsHeading}
              </div>
              <div className={css.authorWhoAmI}>{authorWhoAmI}</div>
            </div>
          </NamedLink>
          {showContactUser && !isFavoriteListing ? (
            <span className={css.favoriteWrapper}>
              <FaRegHeart onClick={handleAddRemoveFavorite} />
            </span>
          ) : showContactUser && isFavoriteListing ? (
            <span className={css.favoriteWrapper}>
              <FaHeart onClick={handleAddRemoveFavorite} />
            </span>
          ) : null}
        </div>
        <NamedLink name="ListingPage" params={{ id, slug }} className={css.authorBio}>
          {bio}
        </NamedLink>
        <ExperienceContainer items={experienceAndTools} />

        <NamedLink name="ListingPage" params={{ id, slug }} className={css.imageContainer}>
          <div className={css.aspectWrapper}>
            <LazyImage
              rootClassName={css.rootForImage}
              alt={title}
              image={firstImage}
              variants={['landscape-crop', 'landscape-crop2x']}
              sizes={renderSizes}
            />
          </div>
          {secondImage && (
            <div className={css.aspectWrapper}>
              <LazyImage
                rootClassName={css.rootForImage}
                alt={title}
                image={secondImage}
                variants={['landscape-crop', 'landscape-crop2x']}
                sizes={renderSizes}
              />
            </div>
          )}
          {thirdImage && (
            <div className={css.aspectWrapper}>
              <LazyImage
                rootClassName={css.rootForImage}
                alt={title}
                image={thirdImage}
                variants={['landscape-crop', 'landscape-crop2x']}
                sizes={renderSizes}
              />
            </div>
          )}
          {fourthImage && viewportWidth > VIEWPORT_SMALL ? (
            <div className={css.aspectWrapper}>
              <LazyImage
                rootClassName={css.rootForImage}
                alt={title}
                image={fourthImage}
                variants={['landscape-crop', 'landscape-crop2x']}
                sizes={renderSizes}
              />
            </div>
          ) : null}
        </NamedLink>

        <NamedLink name="ListingPage" params={{ id, slug }} className={css.info}>
          <div className={css.title}>{title}</div>

          {/* <div className={css.mainInfo}>
            <div className={css.price}>
              <div className={css.perUnit}>
                <FormattedMessage id={unitTranslationKey} />
              </div>
              <div className={css.priceValue} title={priceTitle}>
                {formattedPrice}
              </div>
            </div>
          </div> */}
        </NamedLink>
      </div>
    </div>
  );
};

ListingUserCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
};

ListingUserCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingUserCardComponent);
