import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import renderHTML from 'react-render-html';

import {
  AvatarLarge,
  ProfileSettingsContainer as Container,
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  NamedLink,
  SuccessStoryBanner,
} from '../../components';
import { LeaveOffPlatformReviewForm } from '../../forms';
import config from '../../config';
import { NotFoundPage, TopbarContainer } from '..';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { withViewport } from '../../util/contextHelpers';
import { ensureUser } from '../../util/data';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import { propTypes } from '../../util/types';
import { loadData, postOffPlatformReview } from './LeaveOffPlatformReviewPage.duck';

import facebookImage from '../../assets/freedomlySocialShare.png';
import twitterImage from '../../assets/freedomlySocialShare.png';
import succesStoryBannerImage from '../../assets/systam-success-story-quote2.webp';

import css from './LeaveOffPlatformReviewPage.css';

const { UUID } = sdkTypes;

const LeaveOffPlatformReviewPageComponent = props => {
  const {
    scrollingDisabled,
    user,
    userShowError,
    userFullName,
    onLeaveOffPlatformReview,
    leaveOffPlatformReviewError,
    intl,
  } = props;

  const [reviewSent, setReviewSent] = useState(false);

  const profileUser = ensureUser(user);
  const displayName = userFullName?.fullName
    ? userFullName?.fullName
    : profileUser?.attributes?.profile?.displayName;
  const bio = profileUser?.attributes?.profile?.publicData?.bio;
  const bioToShow = bio ? renderHTML(bio) : null;

  const profileUserId = profileUser?.id?.uuid;

  //TODO: Virheenkäsittely ja tilan vaihto vasta, kun arvostelu kannassa
  const handleLeaveOffPlatformReview = async values => {
    try {
      await onLeaveOffPlatformReview(
        profileUser.id.uuid,
        values.reviewRating,
        values.content,
        values.authorName
      );
      setReviewSent(true);
    } catch (error) {
      console.error('Failed to leave an off-platform review:', error);
      // Optionally handle errors, e.g., show an error message to the user
    }
  };

  const FreelancerContainer = () => {
    return (
      <div className={css.workExperienceContainer}>
        <Container>
          <div className={classNames(css.asideContent, css.container)}>
            <div className={css.bioNameContainer}>
              <AvatarLarge className={css.avatar} user={user} disableProfileLink />
              <div className={css.displayName}>{displayName}</div>
            </div>
            <div className={css.bio}>{bioToShow}</div>
            {profileUserId ? (
              <div className={css.linkToProfile}>
                <NamedLink
                  name="ProfilePage"
                  params={{ id: profileUserId }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="LeaveOffPlatformReviewPage.linkToProfile" />
                </NamedLink>
              </div>
            ) : null}
          </div>
        </Container>
      </div>
    );
  };

  const mainContent = (
    <div className={css.mainContent}>
      {!reviewSent || (reviewSent && leaveOffPlatformReviewError) ? (
        <div className={css.leaveReviewContainer}>
          <FreelancerContainer />

          <LeaveOffPlatformReviewForm
            displayName={displayName}
            onSubmit={handleLeaveOffPlatformReview}
            leaveOffPlatformReviewError={leaveOffPlatformReviewError}
          />
        </div>
      ) : reviewSent && !leaveOffPlatformReviewError ? (
        <div className={css.reviewState}>
          <div className={css.reviewReviewedContainer}>
            <article>
              <h2>
                <FormattedMessage id="LeaveOffPlatformReviewPage.reviewPublished" />
              </h2>
              {profileUserId ? (
                <p className={css.messageContainer}>
                  <FormattedMessage
                    id="LeaveOffPlatformReviewPage.reviewPublishedMessage"
                    values={{
                      a: chunks => (
                        <NamedLink
                          name="ProfilePage"
                          params={{ id: profileUserId }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {chunks}
                        </NamedLink>
                      ),
                    }}
                  />
                </p>
              ) : null}
              <h2 className={css.freedomlyTitle}>
                <FormattedMessage id="LeaveOffPlatformTestimonialPage.testimonialPublishedFreedomlyTitle" />
              </h2>
              <p>
                <FormattedMessage id="LeaveOffPlatformTestimonialPage.testimonialReviewedMessage2" />
              </p>
              <div className={css.successStoryBannerContainer}>
                <SuccessStoryBanner
                  imgSrc={succesStoryBannerImage}
                  textId={'SuccessStoryBanner.systamText'}
                  fromId={'SuccessStoryBanner.systamFrom'}
                  companyId={'SuccessStoryBanner.systamCompany'}
                  pageName={'SuccessStorySystamPage'}
                  buttonTxtId={'SuccessStoryBanner.tulusCTAText'}
                />
              </div>
            </article>
          </div>
        </div>
      ) : null}
    </div>
  );

  let content;

  if (userShowError && userShowError.status === 404) {
    return <NotFoundPage history={''} location={''} />;
  } else if (userShowError) {
    content = (
      <p className={css.error}>
        <FormattedMessage id="ProfilePage.loadingDataFailed" />
      </p>
    );
  } else {
    content = mainContent;
  }

  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  return (
    <Page
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[
        { url: `${config.canonicalRootURL}${facebookImage}`, width: 1200, height: 630 },
      ]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="LeaveOffPlatformReviewPage"
            authenticationPageTopBar={true}
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.main}>
          <div className={css.content}>{content}</div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

LeaveOffPlatformReviewPageComponent.defaultProps = {
  user: null,
  userShowError: null,
};

const { bool, number, shape } = PropTypes;

LeaveOffPlatformReviewPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  user: propTypes.user,
  userShowError: propTypes.error,
  querycurrentOffPlatformReviewError: propTypes.error,

  // form withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    userId,
    userShowError,
    currentOffPlatformReview,
    querycurrentOffPlatformReviewError,
    leaveOffPlatformReviewError,
    userFullName,
    userFullNameError,
  } = state.LeaveOffPlatformReviewPage;

  const userMatches = getMarketplaceEntities(state, [{ type: 'user', id: userId }]);
  const user = userMatches.length === 1 ? userMatches[0] : null;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    user,
    userShowError,
    currentOffPlatformReview,
    querycurrentOffPlatformReviewError,
    leaveOffPlatformReviewError,
    userFullName,
    userFullNameError,
  };
};

const mapDispatchToProps = dispatch => ({
  onLeaveOffPlatformReview: (freelancerId, reviewRating, content, authorName) =>
    dispatch(postOffPlatformReview(freelancerId, reviewRating, content, authorName)),
});

const LeaveOffPlatformReviewPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withViewport,
  injectIntl
)(LeaveOffPlatformReviewPageComponent);

LeaveOffPlatformReviewPage.loadData = params => {
  const userId = new UUID(params.userId);
  return loadData(userId);
};

export default LeaveOffPlatformReviewPage;
