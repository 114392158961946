import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { manageDisableScrolling } from '../../ducks/UI.duck';

import css from './CodeOfConductModalContent.css';

const CodeOfConductModalContentComponent = ({ intl }) => {
  const subHeading = intl.formatMessage({ id: 'SignupProviderForms.cocSubHeading' });

  const listItemTitle1 = intl.formatMessage({ id: 'SignupProviderForms.cocListItem1' });
  const listItemTitle2 = intl.formatMessage({ id: 'SignupProviderForms.cocListItem2' });
  const listItemTitle3 = intl.formatMessage({ id: 'SignupProviderForms.cocListItem3' });
  const listItemTitle4 = intl.formatMessage({ id: 'SignupProviderForms.cocListItem4' });
  const listItemTitle5 = intl.formatMessage({ id: 'SignupProviderForms.cocListItem5' });
  const listItemTitle6 = intl.formatMessage({ id: 'SignupProviderForms.cocListItem6' });

  const listItemText1 = intl.formatMessage({ id: 'SignupProviderForms.cocListItemText1' });
  const listItemText2 = intl.formatMessage({ id: 'SignupProviderForms.cocListItemText2' });
  const listItemText3 = intl.formatMessage({ id: 'SignupProviderForms.cocListItemText3' });
  const listItemText4 = intl.formatMessage({ id: 'SignupProviderForms.cocListItemText4' });
  const listItemText5 = intl.formatMessage({ id: 'SignupProviderForms.cocListItemText5' });
  const listItemText6 = intl.formatMessage({ id: 'SignupProviderForms.cocListItemText6' });

  const headingAccepted = intl.formatMessage({ id: 'SignupProviderForms.cocHeadingAccepted' });

  const descriptionAccepted = (
    <FormattedMessage
      id="SignupProviderForms.cocDescriptionAccepted"
      values={{
        lineBreak: <br />,
      }}
    />
  );

  return (
    <div className={css.cocModal}>
      <h2 className={css.mainHeading}>{headingAccepted}</h2>
      <p className={css.description}>{descriptionAccepted}</p>
      <div className={css.subHeadingCOC}>{subHeading}</div>
      <div className={css.listItem}>
        <div className={css.listItemTitle}>{listItemTitle1}</div>
        <div className={css.listItemText}>{listItemText1}</div>
      </div>
      <div className={css.listItem}>
        <div className={css.listItemTitle}>{listItemTitle2}</div>
        <div className={css.listItemText}>{listItemText2}</div>
      </div>
      <div className={css.listItem}>
        <div className={css.listItemTitle}>{listItemTitle3}</div>
        <div className={css.listItemText}>{listItemText3}</div>
      </div>
      <div className={css.listItem}>
        <div className={css.listItemTitle}>{listItemTitle4}</div>
        <div className={css.listItemText}>{listItemText4}</div>
      </div>
      <div className={css.listItem}>
        <div className={css.listItemTitle}>{listItemTitle5}</div>
        <div className={css.listItemText}>{listItemText5}</div>
      </div>
      <div className={css.listItem}>
        <div className={css.listItemTitle}>{listItemTitle6}</div>
        <div className={css.listItemText}>{listItemText6}</div>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const CodeOfConductModalContent = compose(
  connect(mapDispatchToProps),
  injectIntl
)(CodeOfConductModalContentComponent);

export default CodeOfConductModalContent;
